import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Img,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import FileImage from "../assets/File-Preview 1.png";
import { LuCalendarDays, LuEye } from "react-icons/lu";
import { ModalBody } from "react-bootstrap";
import { GoPaperclip } from "react-icons/go";
import {
  serviceOptions,
  budgetOption,
  industryOption,
  subCategoryServicesData,
} from "utils/constant";
import { useFormik } from "formik";
import * as yup from "yup";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { MdDelete } from "react-icons/md";

import { HSeparator } from "components/separator/Separator";
// import AvatarImg from "views/admin/project/assets/img/avatar1.png";
import { FaRegCalendarAlt } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { GoBell } from "react-icons/go";
import { TbSettingsCog, TbTag } from "react-icons/tb";
import { MdOutlinePriceChange } from "react-icons/md";
import { LiaPercentageSolid } from "react-icons/lia";
import { VscBellSlash } from "react-icons/vsc";
import { FaSackDollar } from "react-icons/fa6";
import { BsClock } from "react-icons/bs";
import { Progress } from "@chakra-ui/react";
import { useMediaQuery } from "@mui/material";

const validatePortfolioSchema = yup.object().shape({
  clientName: yup.string().required("Client Name is Required"),
  projectName: yup.string().required("Project Name is Required"),
  description: yup.string().required("Description is Required"),
  budget: yup.string().required("Budget is Required"),
  service: yup.string().required("Service is  Required"),
  subCategoryService: yup.string().required("Service is  Required"),
  industry: yup.string().required("Industry is Required"),
});
const PortfolioCard = ({ portfolio }) => {
  const [addPortfolioModalOpen, setAddPortfolioModalOpen] = useState(false);
  const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ismobile = useMediaQuery("(max-width:720px)");

  const editPortfolio = async (values, actions) => {
    try {
      const portfolioDocRef = doc(db, "portfolio", portfolio?.id);
      await updateDoc(portfolioDocRef, values);
      setAddPortfolioModalOpen(false);
      actions.resetForm();
    } catch (error) {
      console.log(error.message);
    }
  };
  const formik = useFormik({
    initialValues: {
      clientName: portfolio?.clientName,
      projectName: portfolio?.projectName,
      description: portfolio?.description,
      budget: portfolio?.budget,
      endDate: portfolio?.endDate,
      service: portfolio?.service,
      subCategoryService: portfolio?.subCategoryService ?? [],
      proofOfWork: portfolio?.proofOfWork,
    },
    validationSchema: validatePortfolioSchema,
    onSubmit: editPortfolio,
  });

  useEffect(() => {
    formik.setValues(portfolio);
  }, [portfolio, addPortfolioModalOpen]);

  const handleDelete = () => {
    // Implement your delete logic here
    console.log("Deleting portfolio:", portfolio);
  };
  return (
    <>
      {ismobile ? (
        <Box
          mt="1rem"
          w={ismobile?'260px':'30%'} // Responsive width
          borderRadius="1rem"
          marginBottom="1.2rem"
          margin="auto" // Center align for small screens
          boxShadow="0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)"
          backgroundColor="#FFFFFF"
        >
          <Flex
            m="1rem"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap" // Allow wrapping for smaller screens
          >
            <Text fontWeight="bold" fontSize={["sm", "md", "lg"]}>
              {portfolio?.projectName}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<BsThreeDotsVertical />}
                maxWidth="1rem"
              />
              <MenuList>
                <MenuItem
                  icon={<MdDelete />}
                  onClick={handleDelete}
                  style={{ maxWidth: "1rem" }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Flex
            m="1rem"
            justifyContent="center"
            alignItems="center"
            borderRadius="1rem"
            backgroundColor="#D7E9FD"
          >
            <Img
              src={FileImage}
              width={["6rem", "8rem"]}
              height={["8rem", "10rem"]}
            />
          </Flex>
          <Flex
            ml="1rem"
            mr="1rem"
            justifyContent="space-between"
            flexDirection={["column", "row"]} // Stack on smaller screens
            alignItems={["flex-start", "center"]}
          >
            <Text fontSize={["sm", "md"]}>{portfolio?.clientName}</Text>
            <Flex
              alignItems="center"
              onClick={() => {
                setViewDetailsModalOpen(!viewDetailsModalOpen);
              }}
              cursor="pointer"
            >
              <Icon as={LuEye} />
              <Text ml="0.5rem" fontSize={["sm", "md"]}>
                View Details
              </Text>
            </Flex>
          </Flex>
          <Text ml="1rem" color="#007CFF" fontSize={["sm", "md"]}>
            {portfolio?.service}
          </Text>
          <Flex ml="1rem" mb="1rem" alignItems="center">
            <Icon as={LuCalendarDays} m="0.2rem" />
            <Text fontSize={["xs", "sm", "md"]}>{portfolio?.endDate}</Text>
          </Flex>
        </Box>
      ) : (
        <Box
          mt="1rem"
          w="30%"
          borderRadius="1rem"
          marginBottom="1.2rem"
          margin={"1.5%"}
          boxShadow={"0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)"}
          backgroundColor="#FFFFFF"
        >
          <Flex m="1rem" justifyContent="space-between" alignItems="center">
            <Text fontWeight="bold">{portfolio?.projectName}</Text>
            {/* <Icon as={BsThreeDotsVertical} onClick={() => setIsMenuOpen(!isMenuOpen)} cursor={'pointer'} /> */}
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<BsThreeDotsVertical />}
                maxWidth="1rem"
              />
              <MenuList>
                <MenuItem
                  icon={<MdDelete />}
                  onClick={handleDelete}
                  style={{ maxWidth: "1rem" }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Flex
            m="1rem"
            justifyContent="center"
            alignItems="center"
            borderRadius="1rem"
            backgroundColor="#D7E9FD"
          >
            <Img src={FileImage} width="8rem" height="10rem" />
          </Flex>
          <Flex ml="1rem" mr="1rem" justifyContent="space-between">
            <Text>{portfolio?.clientName}</Text>
            <Flex
              alignItems="center"
              onClick={() => {
                setViewDetailsModalOpen(!viewDetailsModalOpen);
              }}
              cursor={"pointer"}
            >
              <Icon as={LuEye} />
              <Text>View Details</Text>
            </Flex>
          </Flex>
          <Text ml="1rem" color="#007CFF">
            {portfolio?.service}
          </Text>
          <Flex ml="1rem" mb="1rem">
            <Icon as={LuCalendarDays} m="0.2rem" />
            <Text>{portfolio?.endDate}</Text>
          </Flex>
        </Box>
      )}

      <Modal
        size="xl"
        isOpen={viewDetailsModalOpen}
        onClose={() => {
          setViewDetailsModalOpen(!viewDetailsModalOpen);
        }}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#EBF2FA">
          <ModalHeader>Digitial Marketing</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ padding: "20px" }}>
            <Text>Details</Text>
            <Flex pt="1rem" pb="1rem">
              <Flex w="50%">
                <Avatar size="sm" />
                <Input
                  ml="0.5rem"
                  variant="flushed"
                  placeholder="CustomerName"
                  disabled={true}
                />
              </Flex>
              <Flex w="50%" alignItems="center">
                <Icon as={TbSettingsCog} />
                <Input ml="0.5rem" variant="flushed" placeholder="SEO"  disabled={true} />
              </Flex>
            </Flex>
            <Flex pt="1rem" pb="1rem">
              <Flex w="50%" alignItems="center">
                <Icon as={SlGraph} />
                <Input
                  ml="0.5rem"
                  variant="flushed"
                  placeholder="Banking & Finance"
                  disabled={true}
                />
              </Flex>
              <Flex w="50%" alignItems="center">
                <Icon as={FaRegCalendarAlt} />
                <Input
                  ml="0.5rem"
                  variant="flushed"
                  placeholder="24 Jan 2024 to 24 Feb 2024"
                  disabled={true}
                />
              </Flex>
            </Flex>
            <Text>Pricings</Text>
            <Flex pt="1rem" pb="1rem">
              <Flex w="50%" alignItems="center">
                <Icon as={BsClock} />
                <Input ml="0.5rem" variant="flushed" placeholder="2 Weeks"  disabled={true}/>
              </Flex>
              <Flex w="50%" alignItems="center">
                <Icon as={GoBell} />
                <Input
                  ml="0.5rem"
                  variant="flushed"
                  placeholder="Immediately"
                  disabled={true}
                />
              </Flex>
            </Flex>
            <Flex pt="1rem" pb="1rem" w="50%" alignItems="center">
              <Icon as={TbTag} />
              <Input variant="flushed" placeholder="Banking & Finance"  disabled={true} />
            </Flex>
            <Text>Payment Details</Text>
            <Flex pt="1rem" pb="1rem">
              <Flex w="50%" alignItems="center">
                <Icon as={MdOutlinePriceChange} />
                <Input ml="0.5rem" variant="flushed" placeholder="25000"  disabled={true}/>
              </Flex>
              <Flex w="50%" alignItems="center">
                <Icon as={LiaPercentageSolid} />
                <Input ml="0.5rem" variant="flushed" placeholder="500" disabled={true} />
              </Flex>
            </Flex>
            <Flex pt="1rem" pb="1rem">
              <Flex w="50%" alignItems="center">
                <Icon as={VscBellSlash} />
                <Input ml="0.5rem" variant="flushed" placeholder="2000" disabled={true} />
              </Flex>
              <Flex w="50%" alignItems="center">
                <Icon colorSchema="#89664C" as={FaSackDollar} />
                <Input ml="0.5rem" variant="flushed" placeholder="22500"  disabled={true}/>
              </Flex>
            </Flex>
            <Text mt="0.8rem" mb="0.8rem">
              Project Requirement
            </Text>
            <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
            <Text mt="0.8rem" mb="0.8rem" fontSize="sm">
              We are facing a lot of problem in our orgainc growth and we want
              somenone to improve it. This is our requirement.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PortfolioCard;
