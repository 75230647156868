export const Cards = [
    {
        project: "Ongoing Projects",
        number: 0,
        bgColor: "#E5E6F9"
    },
    {
        project: "Completed Projects",
        number: 0,
        bgColor: "#FFF9E9"
    },
    {
        project: "Approved Enquiries",
        number: 0,
        bgColor: "#E1F2EF"
    },
    {
        project: "Rejected Enquiries",
        number: 0,
        bgColor: "#F3F9FE"
    },
    {
        project: "New Leads",
        number: 0,
        bgColor: "#E5E1F9"
    },
    {
        project: "Pending Leads",
        number: 0,
        bgColor: "#FFEEF7"
    }
];
export const ColumnData = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "DATE",
        accessor: "date",
    },
    {
        Header: "PROGRESS",
        accessor: "progress",
    },
    {
        Header: "ACTION",
        accessor: "action"
    }
];
export const RowData = [
    {
        "name": "Project 1",
        "status": "Approved",
        "date": "18 Apr 2022",
        "progress": 75.5,
    },
    {
        "name": "Project 2",
        "status": "Disable",
        "date": "18 Apr 2022",
        "progress": 25.5,
    },
    {
        "name": "Project 3",
        "status": "Error",
        "date": "20 May 2021",
        "progress": 90,
    },
    {
        "name": "Project 4",
        "status": "Approved",
        "date": "12 Jul 2021",
        "progress": 50.5,
    },
    {
        "name": "Project 5",
        "status": "Approved",
        "date": "24 Jan 2024",
        "progress": 60,
    }
];
export const OngoingProjects = [
    {
        category: "SEO",
        subCategory: "Digital Marketing",
        progress: 90,
        progressBarColor: "blue",
        backgroundColor: "#D7E9FD",
        textColor: "#3965FF"
    },
    {
        category: "Website Design",
        subCategory: "Design",
        progress: 50,
        progressBarColor: "pink",
        backgroundColor: "#FFEEF8",
        textColor: "#D53F8C"
    },
    {
        category: "Development",
        subCategory: "Web Development",
        progress: 60,
        progressBarColor: "purple",
        backgroundColor: "#E5E6F9",
        textColor: "#805AD5"
    },
    {
        category: "SEO",
        subCategory: "Digital Marketing",
        progress: 60,
        progressBarColor: "teal",
        backgroundColor: "#E1F3EF",
        textColor: "#319795"
    },
    {
        category: "SEO",
        subCategory: "Digital Marketing",
        progress: 45,
        progressBarColor: "yellow",
        backgroundColor: "#FFF9EA",
        textColor: "#D69E2E"
    },
    {
        category: "Website Design",
        subCategory: "Design",
        progress: 55,
        progressBarColor: "blue",
        backgroundColor: "#C6DBF3",
        textColor: "#3965FF"
    },
    {
        category: "SEO",
        subCategory: "Digital Marketing",
        progress: 70,
        backgroundColor: "#C6DBF3",
        textColor: "#3965FF"

    },
    {
        category: "SEO",
        subCategory: "Digital Marketing",
        progress: 20,
        backgroundColor: "#C6DBF3",
        textColor: "#3965FF"
    },
    {
        category: "Marketing",
        subCategory: "Advertising",
        progress: 70,
        backgroundColor: "#C6DBF3",
        textColor: "#3965FF"
    },
];
export const EnquiryColumn = ["Details", "Date", "Name", "Budget", "Industry", "Timeline", "Status", "Engage"];
export const EnquiryRow = [
    {
        category: "SEO",
        subCategory: "Digital Marketing",
        date: "24 Jan 2024",
        time: "8:05 AM",
        name: "Client 1",
        budget: "5-7K",
        industry: "Banking",
        timeline: "2 Week",
        status: "Available",
    },
    {
        category: "Design",
        subCategory: "Website Design",
        date: "24 Jan 2024",
        time: "8:05 AM",
        name: "Client 2",
        budget: "5-7K",
        industry: "IT",
        timeline: "10 Week",
        status: "Available",
    },
    {
        category: "Web Development",
        subCategory: "Web Application",
        date: "24 Jan 2024",
        time: "8:05 AM",
        name: "Client 3",
        budget: "50-70K",
        industry: "E-Commerce",
        timeline: "6 months",
        status: "Available",
    },
    {
        category: "Marketing",
        subCategory: "Social Media Marketing",
        date: "24 Jan 2024",
        time: "8:05 AM",
        name: "Client 4",
        budget: "15-27K",
        industry: "E-commerce",
        timeline: "5 Week",
        status: "Available",
    },
];
export const PaymentHistoryColumn = ["Project Name", "Date", "Invoice", "Amount", "TDS (2%)", "Commission", "Final ₹", "Status"];
export const PaymentHistoryRow = [
    {
        projectName: "Project 1",
        clientName: "Client 1",
        date: "24 Jan 2024",
        time: "8:05 AM",
        invoice: "BIZ3008001",
        amount: "₹25000",
        tds: "₹500",
        commission: "₹2000",
        final: "₹22500",
        status: "Received"
    },
    {
        projectName: "Project 2",
        clientName: "Client 2",
        date: "24 Jan 2024",
        time: "8:05 AM",
        invoice: "BIZ3008001",
        amount: "₹25000",
        tds: "₹500",
        commission: "₹2000",
        final: "₹22500",
        status: "Pending"
    },
    {
        projectName: "Project 3",
        clientName: "Client 3",
        date: "24 Jan 2024",
        time: "8:05 AM",
        invoice: "BIZ3008001",
        amount: "₹25000",
        tds: "₹500",
        commission: "₹2000",
        final: "₹22500",
        status: "Received"
    },
    {
        projectName: "Project 4",
        clientName: "Client 4",
        date: "24 Jan 2024",
        time: "8:05 AM",
        invoice: "BIZ3008001",
        amount: "₹25000",
        tds: "₹500",
        commission: "₹2000",
        final: "₹22500",
        status: "Received"
    },
]
export const Months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const CustomerDecisionPriority = [
    [
        {
            "bgColor": "#65C756",
            "color": "#FFFFFF",
            "text": "Cost"
        },
        {
            "bgColor": "#65C756",
            "color": "#FFFFFF",
            "text": "Experience"
        },
        {
            "bgColor": "#E0E0E0",
            "color": "#263238CC",
            "text": "Quality work"
        },
    ],
    [
        {
            "bgColor": "#E0E0E0",
            "color": "#263238CC",
            "text": "Timeline"
        },
        {
            "bgColor": "#E0E0E0",
            "color": "#263238CC",
            "text": "Ratings"
        },
        {
            "bgColor": "#65C756",
            "color": "#FFFFFF",
            "text": "Payment Structure"
        },
    ]
]
