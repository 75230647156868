import { Avatar, Box, Flex, Icon, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VisuallyHidden, VisuallyHiddenInput } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Image from "views/partner/partnerProfile/assets/avatar1.png";
import Personal from './components/personal';
import BusinessInfo from './components/businessInfo';
import Bank from './components/bank';
import Verification from './components/verification';
import Services from './components/services';
import { FaRegEdit } from 'react-icons/fa';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from 'config/firebase';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from 'config/firebase';
import { getCurrentPartner } from 'services/PartnerService';
import { Spinner } from 'react-bootstrap';
import useUserDisplayName from 'hooks/useUserDisplayName';
import { auth } from 'config/firebase';
import Personal_icon from "../partnerProfile/assets/Personal.png"
import Businessinfo_icon from "../partnerProfile/assets/Business_Info.png"
import Bank_icon from "../partnerProfile/assets/Bank.png"
import Verification_icon from "../partnerProfile/assets/Verification.png"
import Services_icon from "../partnerProfile/assets/Services.png"
import Arrow_icon from "../partnerProfile/assets/ooui_next-ltr.png"
import { useMediaQuery } from '@mui/material';


const Profile = () => {
    const profilePicInputRef = useRef(null);
    const [profilePicture, setProfilePicture] = useState(Image);
    const [partnerId, setPartnerId] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [partnerData,setPartnerData]=useState({})
    const { displayName } = useUserDisplayName(db, auth);
    const [personalPopup,setPersonalPopup]=useState(false);
    const [businessInfoPopup,setBusinessInfoPopup]=useState(false);
    const [verificationPopup,setVerificationPopup]=useState(false);
    const [bankPopup,setBankPopup]=useState(false);
    const [servicesPopup,setServicesPopup]=useState(false);
    const isMobile=useMediaQuery('(max-width:640px)')
    const isSmallMobile=useMediaQuery('(max-width:400px)');
    
  
    const handlePersonalPopup=()=>{
      if(personalPopup===false){
        setPersonalPopup(true);
      }else{
        setPersonalPopup(false);
      }
    }
  
    const handleBusinessInfoPopup=()=>{
      if(businessInfoPopup===false){
        setBusinessInfoPopup(true);
      }else{
        setBusinessInfoPopup(false);
      }
    }

    const handleBankPopup=()=>{
        if(bankPopup===false){
          setBankPopup(true);
        }else{
          setBankPopup(false);
        }
      }

      const handleServicesPopup=()=>{
        if(servicesPopup===false){
          setServicesPopup(true);
        }else{
          setServicesPopup(false);
        }
      }

      const handleVerificationPopup=()=>{
        if(verificationPopup===false){
          setVerificationPopup(true);
        }else{
          setVerificationPopup(false);
        }
      }

    useEffect(() => {
        getPartnerProfile()
    }, [])

    const handleSelectFile = async (event) => {
        setIsLoading(true)
        if(event.target.files && event.target.files.length>0){
            const file = event.target.files[0];
            const fileName = file.name;
            const storageRef = ref(storage, `profilePicture/${fileName}`);
            try {
                await uploadBytes(storageRef, file);
                const downloadUrl = await getDownloadURL(storageRef);
                const profileRef = doc(db, "partners", partnerId);
                await updateDoc(profileRef, { profile: downloadUrl });
                setProfilePicture(downloadUrl);
    
            } catch (error) {
                console.error('Upload error:', error);
            } finally{
                setIsLoading(false)
            }
        }
       
    }

    const getPartnerProfile = async () => {
        try {
            const partnerUid = sessionStorage.getItem('uid')
            const queryForGetPartner = query(collection(db, "partners"), where("uid", "==", partnerUid));
            const querySnapshot = await getDocs(queryForGetPartner);
            if (!querySnapshot.empty) {
                const partnerData = querySnapshot.docs[0]?.data();
                const partnerId=querySnapshot.docs[0]?.id
                setPartnerData(partnerData)
                setPartnerId(partnerId)
                setProfilePicture(partnerData?.profile);
            } 
        } catch (error) {
            console.error("Error getting partner profile:", error.message);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <div className="d-flex flex-row mb-3">
            {isLoading && (
                <Flex justifyContent={'center'}>
                    <Spinner size="xl" marginTop={'5rem'} backdropBlur="blur" />
                </Flex>
            )}
            <Flex alignItems="center" flexDirection={isMobile?"column":"row"}>
                <Box>
                    <Avatar size={isMobile?'xl':'2xl'} name={partnerData.firstName || displayName} src={profilePicture} onClick={() => { profilePicInputRef.current.click() }} style={{cursor:"pointer"}}/>
                    <Input
                        id="image"
                        name="image"
                        type="file"
                        className='hidden'
                        ref={profilePicInputRef}
                        onChange={handleSelectFile}
                        style={{ display: "none" }}
                    />
                </Box>

                <Flex ml="1rem" flexDirection="column">
                    <Text fontSize="2rem" fontWeight="bold">{partnerData?.firstName??""} {partnerData?.middleName??""} {partnerData?.lastName??""}</Text>
                    {displayName && <Text fontSize={isMobile?"18px":"2rem"} fontWeight="bold">{displayName}</Text>}
                    <Text fontSize={(isMobile&&"12px")}>Profile Completion : 100%</Text>
                </Flex>
            </Flex>
            {isMobile?<div style={{display:"flex",flexDirection:"column",height:"max-content",width:"100%",alignItems:"center"}}>
      <div style={{boxShadow:"0 1px 0 gray",height:"1px",width:"88%",marginTop:"20px",marginBottom:"20px"}}></div>
        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={Personal_icon} alt="personal_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:((isSmallMobile&&"90px")||(isMobile&&"140px"))}}>Personal</p> <img src={Arrow_icon} alt="arrow_icon" style={{transform:personalPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handlePersonalPopup} /></div>
        {personalPopup?<div style={{width:"100%"}}><Personal/></div>:<div></div>}

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={Businessinfo_icon} alt="businessInfo_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:((isSmallMobile&&"50px")||(isMobile&&"100px")),minWidth:"100px"}}>Business Info</p> <img src={Arrow_icon} alt="arrow_icon" style={{transform:businessInfoPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleBusinessInfoPopup} /></div>
        {businessInfoPopup?<div style={{width:"100%"}}><BusinessInfo/></div>:<div></div>} 

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={Bank_icon} alt="businessInfo_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:((isSmallMobile&&"50px")||(isMobile&&"100px")),minWidth:"100px"}}>Bank</p> <img src={Arrow_icon} alt="arrow_icon" style={{transform:bankPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleBankPopup} /></div>
        {bankPopup?<div style={{width:"100%"}}><Bank/></div>:<div></div>} 

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={Verification_icon} alt="businessInfo_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:((isSmallMobile&&"50px")||(isMobile&&"100px")),minWidth:"100px"}}>Verification</p> <img src={Arrow_icon} alt="arrow_icon" style={{transform:verificationPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleVerificationPopup} /></div>
        {verificationPopup?<div style={{width:"100%"}}><Verification/></div>:<div></div>} 

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={Services_icon} alt="businessInfo_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:((isSmallMobile&&"50px")||(isMobile&&"100px")),minWidth:"100px"}}>Services</p> <img src={Arrow_icon} alt="arrow_icon" style={{transform:servicesPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleServicesPopup} /></div>
        {servicesPopup?<div style={{width:"100%"}}><Services/></div>:<div></div>} 
      </div>:<div style={{ marginTop: "2rem" }} >
                <Tabs variant='soft-rectangle' colorScheme='#FFFFFF' >
                    <TabList ml='4' mr='3'>
                        <Tab backgroundColor="#FFFFFF" mr='6' borderRadius="8px" paddingInline={'5'}>Personal</Tab>
                        <Tab backgroundColor="#FFFFFF" mr='6' borderRadius="8px" paddingInline={'5'}>Business Info</Tab>
                        <Tab backgroundColor="#FFFFFF" mr='6' borderRadius="8px" paddingInline={'5'}>Bank</Tab>
                        <Tab backgroundColor="#FFFFFF" mr='6' borderRadius="8px" paddingInline={'5'}>Verification</Tab>
                        <Tab backgroundColor="#FFFFFF" mr='6' borderRadius="8px" paddingInline={'5'}>Services</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Personal />
                        </TabPanel>
                        <TabPanel>
                            <BusinessInfo />
                        </TabPanel>
                        <TabPanel>
                            <Bank />
                        </TabPanel>
                        <TabPanel>
                            <Verification />
                        </TabPanel>
                        <TabPanel>
                            <Services />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>}
            
        </div>
    )
}

export default Profile
