export const ServiceTableRow = ["Service", "Sub Category", "Min Price", "Max Price"];

export const ServiceTableColumn = [
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
    {
        service: "Digital Marketing",
        subCategory: "SEO",
        maxPrice: "₹11000",
        minPrice: "₹22500",
    },
];

export const States = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Maharashtra",
    "Madhya Pradesh",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Tripura",
    "Telangana",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
]