export const ColumnData = ["Details","Date","Name","Budget","Industry","Timeline","Status","Delivery"];

export const RowData= [
    {
        detail: "SEO",
        date: "24 Jan 2024",
        cName: "Customer Name",
        budget: "₹5-7K",
        banking: "Banking",
        timeline: "2 Week",
        status: "Available",
        delivery: "Immediately"
    },
    {
        detail: "SEO",
        date: "24 Jan 2024",
        cName: "Customer Name",
        budget: "₹5-7K",
        banking: "Banking",
        timeline: "2 Week",
        status: "Available",
        delivery: "Immediately"
    },
    {
        detail: "SEO",
        date: "24 Jan 2024",
        cName: "Customer Name",
        budget: "₹5-7K",
        banking: "Banking",
        timeline: "2 Week",
        status: "Available",
        delivery: "Immediately"
    },
]